import React from "react"

export const LogoTypeSmall = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 110 18.8" style={{enableBackground: 'new 0 0 110 18.8'}} xmlSpace="preserve">
    <g>
        <path d="M1.4,16.6c0.2,0.1,0.6,0.2,0.9,0.2c0.7,0,1.2-0.2,1.5-0.9l0.5-1.2L0,4h2.4l3.1,7.9L8.6,4h2.5L6,16.4
            c-0.7,1.8-2,2.4-3.6,2.4c-0.3,0-1-0.1-1.3-0.2L1.4,16.6z"/>
        <path d="M11.8,9.3c0-3.1,2.2-5.5,5.3-5.5c3.2,0,5.2,2.4,5.2,5.7V10h-8.1c0.2,1.6,1.4,2.9,3.4,2.9c1,0,2.2-0.4,3-1.2
            l1,1.5c-1,1-2.6,1.5-4.3,1.5C14.2,14.8,11.8,12.6,11.8,9.3z M17.2,5.6c-2,0-2.9,1.5-3,2.8h5.9C20.1,7.2,19.3,5.6,17.2,5.6z"/>
        <path d="M24.6,14.5V0h2.3v14.5H24.6z"/>
        <path d="M29.9,14.5V0h2.3v14.5H29.9z"/>
        <path d="M34.5,9.3c0-3,2.1-5.5,5.4-5.5c3.4,0,5.4,2.5,5.4,5.5c0,3-2.1,5.5-5.4,5.5C36.6,14.8,34.5,12.3,34.5,9.3z
             M43,9.3c0-1.9-1.1-3.5-3.1-3.5c-1.9,0-3,1.6-3,3.5c0,1.9,1.1,3.5,3,3.5C41.9,12.8,43,11.1,43,9.3z"/>
        <path d="M56.8,14.5l-2.4-7.7L52,14.5h-2.4L46.3,4h2.4l2.2,7.7L53.4,4h2l2.5,7.7L60.1,4h2.4l-3.3,10.5H56.8z"/>
        <path d="M63.5,9.3c0-3.2,2.2-5.5,5.5-5.5c2.1,0,3.3,0.9,4,1.9L71.4,7C70.8,6.2,70,5.8,69,5.8c-1.9,0-3.2,1.4-3.2,3.5
            s1.3,3.5,3.2,3.5c1,0,1.8-0.4,2.4-1.2l1.5,1.4c-0.7,1-2,1.9-4,1.9C65.7,14.8,63.5,12.5,63.5,9.3z"/>
        <path d="M74.3,9.3c0-3,2.1-5.5,5.4-5.5c3.4,0,5.4,2.5,5.4,5.5c0,3-2.1,5.5-5.4,5.5C76.4,14.8,74.3,12.3,74.3,9.3z
             M82.8,9.3c0-1.9-1.1-3.5-3.1-3.5c-1.9,0-3,1.6-3,3.5c0,1.9,1.1,3.5,3,3.5C81.7,12.8,82.8,11.1,82.8,9.3z"/>
        <path d="M94.9,14.5v-1.4c-0.8,1-2,1.7-3.4,1.7c-2.7,0-4.7-2.1-4.7-5.5c0-3.4,2-5.5,4.7-5.5c1.3,0,2.5,0.6,3.4,1.7V0
            h2.3v14.5H94.9z M94.9,11.4V7.2c-0.5-0.8-1.6-1.4-2.7-1.4c-1.8,0-3,1.5-3,3.5c0,2,1.2,3.5,3,3.5C93.2,12.8,94.3,12.2,94.9,11.4z"/>
        <path d="M99.5,9.3c0-3.1,2.2-5.5,5.3-5.5c3.2,0,5.2,2.4,5.2,5.7V10h-8.1c0.2,1.6,1.4,2.9,3.4,2.9c1,0,2.2-0.4,3-1.2
            l1,1.5c-1,1-2.6,1.5-4.3,1.5C101.8,14.8,99.5,12.6,99.5,9.3z M104.8,5.6c-2,0-2.9,1.5-3,2.8h5.9C107.7,7.2,106.9,5.6,104.8,5.6z"/>
    </g>
    </svg>    
);

export const Logo = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 44 44" style={{enableBackground: 'new 0 0 44 44'}} xmlSpace="preserve">
        <path d="M22,11.6c2.8,0,5.4,1.1,7.4,3.1s3.1,4.6,3.1,7.4s-1.1,5.4-3.1,7.4s-4.6,3.1-7.4,3.1s-5.4-1.1-7.4-3.1
            s-3.1-4.6-3.1-7.4s1.1-5.4,3.1-7.4S19.2,11.6,22,11.6 M22,0C9.8,0,0,9.8,0,22s9.8,22,22,22s22-9.8,22-22S34.2,0,22,0L22,0z"/>
    </svg>
);

export const Plus = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 72 72" style={{enableBackground: 'new 0 0 72 72'}} xmlSpace="preserve">
        <path d="M62.7,26.7H45.3V9.3C45.3,4.2,41.1,0,36,0h0c-5.1,0-9.3,4.2-9.3,9.3v17.4H9.3C4.2,26.7,0,30.9,0,36
            s4.2,9.3,9.3,9.3h17.4v17.4c0,5.1,4.2,9.3,9.3,9.3h0c5.1,0,9.3-4.2,9.3-9.3V45.3h17.4c5.1,0,9.3-4.2,9.3-9.3S67.8,26.7,62.7,26.7z"
            />
    </svg>
);

export const LogoAndType = () => (
    <div id="Logo">
        <Logo />
        <span>
            <LogoTypeSmall />
        </span>
    </div>
);